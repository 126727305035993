<p-menubar [model]="menuItems">
  <ng-template pTemplate="start">
    <a routerLink="/login">
      <app-logo></app-logo>
    </a>
  </ng-template>

  <ng-template pTemplate="end">
    <div class="right-bar">
      <div class="languge">
        <div (click)="openLangModel()" class="language">
          <img [src]="lang.icon" alt="" srcset="" />
          <span> {{ lang.code }} </span>
        </div>
        <div class="dropdown-container">
          <div *ngIf="langModel" class="language-dropdown">
            <div (click)="changeLang('en')"><img [src]="engIcon" alt="" srcset="" /> <span> English </span></div>
            <div (click)="changeLang('swe')"><img [src]="swedIcon" alt="" srcset="" /> <span> Swedish</span></div>
          </div>
        </div>
      </div>
      <!-- <app-logout-button></app-logout-button> -->
      <div class="profile">
        <div (click)="openProfileMOdel()" class="language profileicon">
          <div>
            <img [src]="profileIcon" alt="" srcset="" />
          </div>
          <span> {{ firstName }} </span>
        </div>
        <div class="dropdown-container">
          <div *ngIf="profileModel" class="language-dropdown">
            <div (click)="changeRoute()">
              <img [src]="profileIcon" alt="" srcset="" /> <span> {{ 'menu.profile' | translate }} </span>
            </div>
            <div (click)="logout()">
              <img [src]="logoutIcon" alt="" srcset="" /> <span> {{ 'general.logout' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-menubar>
<!-- menuItems -->