import { Routes } from '@angular/router'
import { LoggedInGuard } from '@guards/logged-in.guard'
import { LayoutComponent } from '@components/layout/layout.component'
import { NotLoggedInGuard } from '@guards/not-logged.guard'

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [LoggedInGuard],
    // canActivate: [NotLoggedInGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'appointments',
        pathMatch: 'full',
      },
      {
        path: 'appointments',
        loadChildren: () => import('@home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'appointmentDetails',
        loadChildren: () => import('@appointments/appointments.module').then(m => m.AppointmentsModule),
      },
      {
        path: 'inbox',
        loadChildren: () => import('@inbox/inbox.module').then(m => m.InboxModule),
      },
      {
        path: 'mdcare',
        loadChildren: () => import('@mdcare/mdcare.module').then(m => m.MdcareModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./routes/account/profile/profile.module').then(m => m.ProfileModule),
      },

      {
        path: 'adresses',
        loadChildren: () => import('./routes/account/adresses/adresses.module').then(m => m.adressesModule),
      },
      {
        path: 'billings',
        loadChildren: () => import('./routes/account/billing/billing.module').then(m => m.BillingModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./routes/account/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'helthprofile',
        loadChildren: () =>
          import('./routes/account/helth-profile/helth-profile.module').then(m => m.HelthProfileModule),
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./routes/account/notifications/notifications.module').then(m => m.NotificationModule),
      },
      {
        path: 'patients',
        loadChildren: () => import('./routes/account/patients/patients.module').then(m => m.PatientsModule),
      },
      {
        path: 'subscription',
        loadChildren: () => import('./routes/account/subscription/subscription.module').then(m => m.SubscriptionModule),
      },
      {
        path: 'visa',
        loadChildren: () => import('./routes/account/visa/visa.module').then(m => m.VisaModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./routes/account/account/account.module').then(m => m.accountModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./routes/account/account/account.module').then(m => m.accountModule),
      },
      {
        path: 'new-appointment',
        loadChildren: () => import('./routes/new-appointments/appointments.module').then(m => m.NewAppointmentsModule),
      },
      {
        path: 'summary/:id',
        loadChildren: () => import('./routes/summary/summary.module').then(m => m.SummaryModule),
      },
      {
        path: 'language',
        loadChildren: () => import('./routes/languages/language.module').then(m => m.LanguageModule),
      },
      {
        path: 'appointments/:id',
        loadChildren: () =>
          import('./routes/account/appointment-details/appointment-details.module').then(
            m => m.AppointmentDetailsModule,
          ),
      },
      {
        path: 'menu',
        loadChildren: () => import('./routes/menu/menu.module').then(m => m.MenuModule),
      },
      {
        path: 'card',
        loadChildren: () => import('./routes/account/card/card.module').then(m => m.CardModule),
      },
    ],
  },
  {
    path: 'login',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('@login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'loginSpecial',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('@login/loginSpecial/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('@register/register.module').then(m => m.RegisterModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
]
