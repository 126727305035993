import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-step',
  templateUrl: './details-step.component.html',
  styleUrls: ['./details-step.component.scss']
})
export class DetailsStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
