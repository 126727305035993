import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-step',
  templateUrl: './address-step.component.html',
  styleUrls: ['./address-step.component.scss']
})
export class AddressStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
